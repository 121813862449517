/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import {FullReport} from "../pages/FullReport";
import {Lv1Report} from "../pages/Lv1Report";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const CustomerReportImage = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const characterId = currentUser.user.character_id;
  const minorCharacterId = currentUser.user.minor_character_id;
  const roadCharacterId = currentUser.user.road_character_id;
  const spiritLevel = currentUser.user.spirit_level;
  const isFirst = currentUser.user.is_first;
  const [showButton, setShowButton] = useState(true);
  const reportVideo = useRef()
  const [errorMessage, setErrorMessage] = useState('');

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <>
      {isActive && isScan
        ?
          <>
            <FullReport />
          </>
        :
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff',
                  padding: '20px'
                }}
              >
                {/*<img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-report3.jpg')} alt=""/>*/}

                {LANGUAGE === 'VI' && <>
                  <h2>Mời bạn liên hệ với cộng tác viên của Anbi qua số điện thoại <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> để được hỗ trợ mở khóa bản phân tích.</h2>
                </>}
                {LANGUAGE === 'EN' && <>
                  <h2>Please contact Anbi's collaborators via phone number <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> for support unlocking the analysis.</h2>
                </>}

                <div style={{ padding: '10px', display: !isFirst ? 'block' : 'none' }}>
                  <div
                    onClick={() => dispatch(auth.actions.logout())}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                    <h4 style={{ color: '#fff' }}>
                      {LANGUAGE === 'VI'
                        ? 'Đăng xuất'
                        : 'Log out'
                      }
                    </h4>
                  </div>
                </div>

                <MenuTwo active='report' />

              </div>

            </div>
          </>
      }

    </>
  )
}

export {CustomerReportImage}
