import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import * as auth from "../modules/auth/redux/AuthRedux";

const CustomerNoelListStudy = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [errorMessage, setErrorMessage] = useState('');
  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;
  const dispatch = useDispatch()
  const isFirst = currentUser.user.is_first;
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const resultArray = JSON.parse(currentUser.user.result) ?? []
  const studyVi = {
    1: 'Chân thành',
    2: 'Trung thực',
    3: 'Nhân ái',
    4: 'Dũng cảm',
    5: 'Kiên nhẫn',
    6: 'Hiếu thảo',
    7: 'Tự trọng',
    8: 'Khoan dung',
    9: 'Trách nhiệm',
    10: 'Đoàn kết',
    11: 'Thấu hiểu',
    12: 'Lạc quan',
    13: 'Đáng tin cậy',
    14: 'Khiêm tốn',
    15: 'Ham học hỏi',
    16: 'Tôn trọng người khác',
    17: 'Đồng cảm',
    18: 'Sáng tạo',
    19: 'Cầu tiến',
    20: 'Tử tế',
    21: 'Tự tin',
    22: 'Chăm chỉ',
    23: 'Quyết tâm',
    24: 'Linh hoạt',
    25: 'Biết lắng nghe',
    26: 'Hài hước',
    27: 'Công bằng',
    28: 'Kỷ luật',
    29: 'Trí tuệ',
    30: 'Biết ơn',
    31: 'Nhẫn nhịn',
    32: 'Nhiệt tình',
    33: 'Độc lập',
    34: 'Hòa đồng',
    35: 'Biết chia sẻ',
    36: 'Thực tế',
    37: 'Sâu sắc',
    38: 'Cởi mở',
    39: 'Ngăn nắp',
    40: 'Chu đáo',
    41: 'Quản lý thời gian tốt',
    42: 'Cẩn trọng',
    43: 'Hiền lành',
    44: 'Kiên định',
    45: 'Tận tụy',
    46: 'Chính trực',
    47: 'Nhẹ nhàng',
    48: 'Nghị lực',
    49: 'Tầm nhìn xa',
    50: 'Tình cảm',
  }

  const studyEn = {
    1: 'Sincerity',
    2: 'Honesty',
    3: 'Compassion',
    4: 'Courage',
    5: 'Patience',
    6: 'Filial Piety',
    7: 'Self-Respect',
    8: 'Forgiveness',
    9: 'Responsibility',
    10: 'Unity',
    11: 'Understanding',
    12: 'Optimism',
    13: 'Trustworthiness',
    14: 'Humility',
    15: 'Eagerness to Learn',
    16: 'Respect for Others',
    17: 'Empathy',
    18: 'Creativity',
    19: 'Progressiveness',
    20: 'Kindness',
    21: 'Confidence',
    22: 'Diligence',
    23: 'Determination',
    24: 'Flexibility',
    25: 'Good Listening',
    26: 'Humor',
    27: 'Fairness',
    28: 'Discipline',
    29: 'Wisdom',
    30: 'Gratitude',
    31: 'Forbearance',
    32: 'Enthusiasm',
    33: 'Independence',
    34: 'Sociability',
    35: 'Generosity',
    36: 'Practicality',
    37: 'Insightfulness',
    38: 'Openness',
    39: 'Tidiness',
    40: 'Consideration',
    41: 'Time Management',
    42: 'Caution',
    43: 'Gentleness',
    44: 'Perseverance',
    45: 'Dedication',
    46: 'Integrity',
    47: 'Gentle',
    48: 'Resilience',
    49: 'Vision',
    50: 'Love',
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {isActive && isScan
        ?
          <>
            {!currentUser.user.is_paid &&
              <div
                style={{ backgroundColor: '#333', height: '100%'}}
              >
                <div
                  style={{
                    width: WIDTH,
                    height: '100%',
                    margin: 'auto',
                    // backgroundColor: 'red',
                    backgroundSize: '100% 100%'
                  }}
                >
                  <div
                    style={{
                      // borderRadius: '15px',
                      padding: '20px',
                      backgroundColor: 'white'
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-block'
                      }}
                    >
                      <p
                        style={{
                          color: '#000',
                          fontSize: '20px',
                          fontFamily: 'Linotte-Regular',
                          textAlign: 'center'
                        }}
                      >
                        Chào bạn, bạn đang trải nghiệm công nghệ đánh giá tâm trạng qua việc phân tích khuôn mặt bằng bản đồ nhiệt với camera trí tuệ nhân tạo Anbi – một công nghệ do các lập trình viên và nhà khoa học Việt Nam nghiên cứu từ năm 2019, với sự giúp đỡ của các chuyên gia đến từ nhiều quốc gia phát triển.
                        Chúng tôi hy vọng bạn đã có trải nghiệm hài lòng với bài học đầu tiên này, và để tham gia trọn vẹn cả 50 bài học rất ý nghĩa giúp hoàn thiện 50 phẩm chất quan trọng cùng nhân vật ông già Noel AI, mong bạn ủng hộ 50k để chúng tôi thiết lập tài khoản lưu trữ khóa học trong 01 năm cho bạn.
                        Mời bạn chụp lại hoặc tải mã QR này về để chuyển khoản chính xác, kích hoạt hệ thống duyệt tự động của Anbi.

                      </p>
                      <p
                        style={{ fontFamily: 'Linotte-Heavy' }}
                        className='fs-1 text-center'>THÔNG TIN CHUYỂN KHOẢN</p>

                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'Linotte-Regular'
                                  }}
                                >
                                  {'Ngân hàng: '}
                                </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '20px',
                            fontFamily: 'Linotte-Heavy'
                          }}
                        >
                                  Quân đội MBBank
                                </span>
                      </div>

                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'Linotte-Regular'
                                  }}
                                >
                                  {'Số tài khoản: '}
                                </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '20px',
                            fontFamily: 'Linotte-Heavy'
                          }}
                        >
                                  1988288288
                                </span>
                        <button className='btn btn-success btn-sm ms-4'
                                style={{ marginTop: '-10px' }}
                                onClick={() => navigator.clipboard.writeText('1988288288')}
                        >
                          Copy
                        </button>
                      </div>

                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Chủ tài khoản: '}
                              </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '20px',
                            fontFamily: 'Linotte-Heavy'
                          }}
                        >
                                Trần Ngọc Thiện
                              </span>
                      </div>


                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'Linotte-Regular'
                                  }}
                                >
                                  {'Số tiền chuyển khoản: '}
                                </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '20px',
                            fontFamily: 'Linotte-Heavy'
                          }}
                        >
                                  50.000 đ
                                </span>
                      </div>

                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'Linotte-Regular'
                                  }}
                                >
                                  {'Nội dung: '}
                                </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '20px',
                            fontFamily: 'Linotte-Heavy'
                          }}
                        >
                                    {'UNG HO ANBI ' + currentUser.user.phone}
                                  </span>
                        <br/>
                        <button className='btn btn-success btn-sm ms-4'
                                style={{ marginTop: '0px' }}
                                onClick={() => navigator.clipboard.writeText('UNG HO ANBI ' + currentUser.user.phone)}
                        >
                          Copy
                        </button>
                      </div>

                      <div
                        style={{
                          margin: '5px',
                          padding: '10px 10px 10px 0px',
                          textAlign: 'center'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Mã QR: '}
                              </span>
                        <br/>
                        <div className='text-center'>
                          <img style={{ width: '100%', borderRadius: '5px', marginTop: '10px' }} src={`https://img.vietqr.io/image/mbbank-1988288288-compact2.jpg?amount=50000&addInfo=UNG%20HO%20ANBI%20${currentUser.user.phone}&accountName=TRAN%20NGOC%20THIEN`} alt=""/>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            }


            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  // backgroundColor: 'red',
                  backgroundImage: `url(${LANGUAGE === 'VI' ? toAbsoluteUrl('/media/logos/background-vi.jpg') : toAbsoluteUrl('/media/logos/background-en.jpg')})`,
                  backgroundSize: '100% 100%'
                }}
              >
                <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px', display: 'none' }}
                     src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/list-study-0.jpg' : '/media/logos/list-study-0e.jpg')}
                     alt=""
                />
                <div
                  className='container'
                  style={{
                    paddingTop: '59%'
                  }}
                >
                  <div className="row g-2 p-4">
                    {resultArray.map((result, resultIndex) =>
                      <div
                        className='col-4' key={result}
                      >
                        <div
                          style={{
                            background: resultIndex <= currentUser.user.result_index ? '#9bc257' : '#d8bac7'
                          }}
                          className="p-3 border rounded">
                          {currentUser.user.is_paid &&
                            <Link to={resultIndex <= currentUser.user.result_index ? '/customer/noel-daily?result=' + result : '/customer/noel-daily'}>
                              <p
                                style={{
                                  color: resultIndex <= currentUser.user.result_index ? '#fff' : '#e6d7e2',
                                  fontSize: '32px',
                                  lineHeight: 1,
                                  marginBottom: '2px',
                                  wordWrap: 'break-word'
                                }}
                              >
                                <span>{resultIndex < 9 ? '0' + (resultIndex + 1) : resultIndex + 1}</span>
                              </p>
                              <p
                                style={{
                                  color: resultIndex <= currentUser.user.result_index ? '#fff' : '#e6d7e2',
                                  fontSize: '24px',
                                  lineHeight: 1,
                                  marginBottom: '2px',
                                  wordWrap: 'break-word'
                                }}
                              >
                                {resultIndex <= currentUser.user.result_index
                                  ?
                                  <>{LANGUAGE === 'VI' ? studyVi[result] : studyEn[result]}</>
                                  :
                                  <>&nbsp; <br/> &nbsp;</>
                                }
                              </p>
                            </Link>
                          }
                          {!currentUser.user.is_paid &&
                          <Link to={'/customer/noel-daily'}>
                            <p
                              style={{
                                color: resultIndex <= currentUser.user.result_index ? '#fff' : '#e6d7e2',
                                fontSize: '32px',
                                lineHeight: 1,
                                marginBottom: '2px',
                                wordWrap: 'break-word'
                              }}
                            >
                              <span>{resultIndex < 9 ? '0' + (resultIndex + 1) : resultIndex + 1}</span>
                            </p>
                            <p
                              style={{
                                color: resultIndex <= currentUser.user.result_index ? '#fff' : '#e6d7e2',
                                fontSize: '24px',
                                lineHeight: 1,
                                marginBottom: '2px',
                                wordWrap: 'break-word'
                              }}
                            >
                              {resultIndex <= currentUser.user.result_index
                                ?
                                <>{LANGUAGE === 'VI' ? studyVi[result] : studyEn[result]}</>
                                :
                                <>&nbsp; <br/> &nbsp;</>
                              }
                            </p>
                          </Link>
                          }
                        </div>

                      </div>

                    )}
                  </div>

                </div>




                <MenuTwo active='noel-study'/>
              </div>

            </div>
          </>
        :
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff',
                  padding: '20px'
                }}
              >
                {LANGUAGE === 'VI' && <>
                  <h2>Mời bạn liên hệ với cộng tác viên của Anbi qua số điện thoại <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> để được hỗ trợ mở khóa bản phân tích.</h2>
                </>}
                {LANGUAGE === 'EN' && <>
                  <h2>Please contact Anbi's collaborators via phone number <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> for support unlocking the analysis.</h2>
                </>}

                <div style={{ padding: '10px', display: !isFirst ? 'block' : 'none' }}>
                  <div
                    onClick={() => dispatch(auth.actions.logout())}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                    <h4 style={{ color: '#fff' }}>
                      {LANGUAGE === 'VI'
                        ? 'Đăng xuất'
                        : 'Log out'
                      }
                    </h4>
                  </div>
                </div>

              </div>

              <MenuTwo active='noel-study' />

            </div>
          </>
      }


    </>
  )

}

export {CustomerNoelListStudy}
