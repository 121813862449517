import React, {useState, useRef, useEffect} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {Link, useHistory} from "react-router-dom";

const CustomerScan = () => {

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch()
  const history = useHistory()

  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [hasError, setHasError] = useState(false)
  const [agencyPhone, setAgencyPhone] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const userInfo = useSelector(state => state.userInfo)
  console.log({userInfo})
  const LANGUAGE = userInfo.language
  const [hasFace, setHasFace] = useState(false);
  const [pressAnalysis, setPressAnalysis] = useState(false);
  const [scanCode, setScanCode] = useState('');
  const videoScan = useRef()

  useEffect(() => {
    window.addEventListener('message', function(event) {
      console.log(event.data); // Message received from child
      if (event.data == '11111') {
        setHasFace(true)
      } else if (!pressAnalysis) {
        setHasFace(false)
      }
    });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div style={{ backgroundColor: '#000', height: '100%'}}>
        <div
          style={{
            width: WIDTH,
            height: '100%',
            margin: 'auto',
            backgroundColor: '#000',
          }}
        >



          <div
            style={{
              width: '100%',
              padding: '0px'
            }}
          >
            {(hasFace && !hasError && !pressAnalysis) && <>
              <button
                type="button"
                style={{
                  width: '100%',
                  padding: '25px',
                  backgroundColor: (hasFace && !hasError) ? '#30a3fc' : '#a1e6b9',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  borderWidth: '0px'
                }}
                onClick={() => {
                  axios.post('https://backend.robotanan.com/api/customer/noel-daily-scan', {
                    access_token: currentUser.user.access_token,
                  }).then(data => data)
                    .then((resData) => {
                      console.log({resData})
                      setPressAnalysis(true)
                      videoScan.current.play()
                      if (resData.data.error_code !== 1) {
                        setHasError(true)
                        setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                        // setTimeout(() => window.location.replace('/'), 3000)
                      }
                    })
                    .catch(err => {
                      console.log(err)
                      setHasError(true)
                      setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                    })
                }}
              >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA',
                marginTop: '5px',
                marginBottom: '5px'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'BẮT ĐẦU PHÂN TÍCH'
                :
                'START ANALYSIS'
              }
            </span>
              </button>
            </>}

            <div>
              <iframe src="https://face.robotanan.com/" style={{ width: '100%', height: '540px', display: pressAnalysis ? 'none' : 'block', overflow: 'hidden' }} allow="camera" />
            </div>

            <video style={{display: !pressAnalysis ? 'none' : 'block' }}
                   ref={videoScan}
                   onEnded={() => { if (!hasError) window.location.replace('/')}}
                   className='w-100'
                   src={LANGUAGE === 'VI' ? "https://backend.robotanan.com/xmas-video-scan/scan.mp4" : "https://backend.robotanan.com/xmas-video-scan/scan_en.mp4"}
            />


            {errorMsg && (<h1 style={{ color: 'red', fontFamily: 'UTM-APTIMA' }} >{errorMsg}</h1>)}

          </div>




        </div>

      </div>

    </div>
  )

}

export {CustomerScan}
