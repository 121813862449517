/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {MenuTwo} from "../components/MenuTwo";

const UpdateFamilyPhone = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const isActive = currentUser.user.is_active;
  const isScan = currentUser.user.is_scan;
  const isChild = currentUser.user.is_child;
  const history = useHistory()
  const dispatch = useDispatch()



  const [oldPassword, setOldPassword] = useState('old_password')
  const [newPassword, setNewPassword] = useState('')
  const [familyPhone, setFamilyPhone] = useState('')
  const [spirit, setSpirit] = useState('A')
  const [errorMessage, setErrorMessage] = useState('')
  const [familyRole, setFamilyRole] = useState('MOTHER');
  const [familyRoles, setFamilyRoles] = useState([
    'MOTHER',
    'FATHER',
    'CHILD_ONE',
    'CHILD_TWO',
    'CHILD_THREE',
    'CHILD_FOUR',
    'CHILD_FIVE',
  ]);

  const confirmChangePassword = () => {
    axios.post(`${BACKEND_URL}/customer/anbiz-update-infor`, {
      access_token: currentUser.user.access_token,
      family_phone: familyPhone,
      family_role: familyRole,
      spirit: spirit,
    }).then(res => res.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setErrorMessage('Cập nhật mã gia đình thành công')
          setTimeout(() => {
            // history.push('/customer/report-image')
            window.location.replace('/customer/report-image')
          }, 2000)
        } else {
          setErrorMessage(resData.message ?? 'Sai thông tin, vui lòng nhập lại')
        }
      })
  }

  useEffect(() => {
    dispatch({
      type: 'SET_IS_FIRST_LOGIN',
      payload: {}
    })
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100px',
            paddingTop: '30px',
            paddingLeft: '20px',
            backgroundColor: '#a14ad9'
          }}
        >
          <h1 style={{color: 'white'}}>
            Cập nhật thông tin
          </h1>
        </div>

        <div
          style={{
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          {isActive && isScan
            ?
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={isChild ? currentUser.user.character.main_image_link : currentUser.user.character_adult.main_image_link} alt=""/>
            :
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={toAbsoluteUrl('/media/logos/inactive-user.jpg')} alt=""/>
          }
          <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
          <div style={{ padding: '10px 40px', textAlign: 'center' }}>
            <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Số điện thoại: '
                : 'Phone number: '
              }
              {currentUser.user.phone}</h4>
          </div>
        </div>

        <div style={{ padding: '20px' }}>
          {errorMessage && <h3 className="text-danger mb-4">{errorMessage}</h3>}

          <div className="">
            <div className="form-group my-4">
              <h4>
                Sức khỏe tinh thần
                <span className="text-danger">*</span>
              </h4>
              <select
                onChange={(e) => setSpirit(e.target.value)}
                style={{
                  width: '100%',
                  height: '40px',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  fontWeight: 'bolder',
                  fontFamily: 'UTM-APTIMABOLD',
                  marginTop: '10px'
                }}
              >
                <option key='A' value='A' style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                  Mức 1: Rất hạnh phúc và luôn cảm thấy biết ơn
                </option>
                <option key='B' value='B' style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                  Mức 2: Cảm thấy hài lòng và thoải mái
                </option>
                <option key='C' value='C' style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                  Mức 3: Cảm thấy bình thường và ổn định
                </option>
                <option key='D' value='D' style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                  Mức 4: Khó hài lòng và thường lo lắng
                </option>
                <option key='E' value='E' style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                  Mức 5: Cảm thấy bất hạnh và thất vọng
                </option>
              </select>
            </div>
            <div className="form-group my-4">
              <h4>
                Mã gia đình (thường là số điện thoại của mẹ)
                <span className="text-danger">*</span>
              </h4>
              <input
                value={familyPhone}
                onChange={(e) => setFamilyPhone(e.target.value)}
                type="text" className="form-control" placeholder=""
              />
            </div>
            <div className="form-group my-4">
              <h4>
                Vai trò của bạn trong gia đình (vui lòng chọn đúng vai trò thực tế để bản đồ giao tiếp được chính xác nhất)
                <span className="text-danger">*</span>
              </h4>
              <select
                onChange={(e) => setFamilyRole(e.target.value)}
                style={{
                  width: '100%',
                  height: '40px',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  fontWeight: 'bolder',
                  fontFamily: 'UTM-APTIMABOLD',
                  marginTop: '10px'
                }}
              >
                {familyRoles.length > 0 && familyRoles.map((familyRole) =>
                  <option key={familyRole} value={familyRole} style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                    {familyRole === 'FATHER' && 'Bố (Chồng)'}
                    {familyRole === 'MOTHER' && 'Mẹ (Vợ)'}
                    {familyRole === 'CHILD_ONE' && 'Con thứ nhất'}
                    {familyRole === 'CHILD_TWO' && 'Con thứ hai'}
                    {familyRole === 'CHILD_THREE' && 'Con thứ ba'}
                    {familyRole === 'CHILD_FOUR' && 'Con thứ tư'}
                    {familyRole === 'CHILD_FIVE' && 'Con thứ năm'}
                  </option>
                )}
              </select>
            </div>
          </div>
        </div>


        <div style={{ padding: '10px' }}>
          <div
            onClick={() => {confirmChangePassword()}}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Xác nhận'
                : 'Confirm'
              }
            </h4>
          </div>
        </div>

        <MenuTwo active='report'/>
      </div>

    </div>
  )
}

export {UpdateFamilyPhone}

