/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import SVG from "react-inlinesvg";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import moment from 'moment'

const FullNoelDaily = () => {
  const audioElement1 = useRef()
  const audioElement2 = useRef()
  const audioElement3 = useRef()
  const audioElement4 = useRef()
  const audioElement5 = useRef()
  const audioElement6 = useRef()
  const audioElement7 = useRef()
  const audioElement8 = useRef()
  const audioElement9 = useRef()
  const audioElement10 = useRef()
  const audioElement11 = useRef()
  const audioElement12 = useRef()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  console.log(currentUser)
  const characterId = currentUser.user.character_id;
  const minorCharacterId = currentUser.user.minor_character_id;
  const roadCharacterId = currentUser.user.road_character_id;
  const spiritLevel = currentUser.user.spirit_level;
  const isFirst = currentUser.user.is_first;
  const [showButton, setShowButton] = useState(true);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const reportVideo = useRef()
  const scanDate = currentUser.user.scan_date;
  let today = moment().format('YYYYMMDD')
  const scanToday = scanDate == today
  console.log({scanDate, today, scanToday})

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const history = useHistory()
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const result = searchParams.get("result");
  // nếu không có result thì lấy result index của result
  const resultArray = JSON.parse(currentUser.user.result) ?? []
  const todayResult = result ?? resultArray[currentUser.user.result_index]
  console.log(todayResult)

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#333333'
        }}
      >
        {!scanToday &&
          <div
            style={{
              padding: '20px',
              backgroundImage: `url('${toAbsoluteUrl('/media/logos/bg_1.jpg')}')`,
              backgroundSize: 'cover',
              height: '100%'
            }}
          >
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                history.push('/customer/scan')
              }}
            >
              <img
                alt=''
                src={toAbsoluteUrl('/media/logos/pt.png')}
                className='w-100'
              />
            </div>
          </div>
        }

        {scanToday &&
          <>
            {/*video*/}
            <div
              style={{
                padding: '20px'
              }}
            >
              <video
                style={{
                  borderRadius: '20px',
                }}
                controls={true}
                className='w-100' src={LANGUAGE === 'VI' ? `https://backend.robotanan.com/xmas-video-vi/${todayResult}.mp4` : `https://backend.robotanan.com/xmas-video-en/${todayResult}.mp4`}
              />
            </div>

            {!currentUser.user.is_paid &&
            <div
              style={{
                borderRadius: '15px',
                padding: '20px',
                backgroundColor: 'white'
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'inline-block'
                }}
              >
                <p
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    fontFamily: 'Linotte-Regular',
                    textAlign: 'center'
                  }}
                >
                  Chào bạn, bạn đang trải nghiệm công nghệ đánh giá tâm trạng qua việc phân tích khuôn mặt bằng bản đồ nhiệt với camera trí tuệ nhân tạo Anbi – một công nghệ do các lập trình viên và nhà khoa học Việt Nam nghiên cứu từ năm 2019, với sự giúp đỡ của các chuyên gia đến từ nhiều quốc gia phát triển.
                  Chúng tôi hy vọng bạn đã có trải nghiệm hài lòng với bài học đầu tiên này, và để tham gia trọn vẹn cả 50 bài học rất ý nghĩa giúp hoàn thiện 50 phẩm chất quan trọng cùng nhân vật ông già Noel AI, mong bạn ủng hộ 50k để chúng tôi thiết lập tài khoản lưu trữ khóa học trong 01 năm cho bạn.
                  Mời bạn chụp lại hoặc tải mã QR này về để chuyển khoản chính xác, kích hoạt hệ thống duyệt tự động của Anbi.

                </p>
                <p
                  style={{ fontFamily: 'Linotte-Heavy' }}
                  className='fs-1 text-center'>THÔNG TIN CHUYỂN KHOẢN</p>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Ngân hàng: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                Quân đội MBBank
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tài khoản: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                1988288288
                              </span>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '-10px' }}
                          onClick={() => navigator.clipboard.writeText('1988288288')}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                            <span
                              style={{
                                color: '#333',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Regular'
                              }}
                            >
                              {'Chủ tài khoản: '}
                            </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                              Trần Ngọc Thiện
                            </span>
                </div>


                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tiền chuyển khoản: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                50.000 đ
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Nội dung: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                  {'UNG HO ANBI ' + currentUser.user.phone}
                                </span>
                  <br/>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '0px' }}
                          onClick={() => navigator.clipboard.writeText('UNG HO ANBI ' + currentUser.user.phone)}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    margin: '5px',
                    padding: '10px 10px 10px 10px',
                    textAlign: 'center'
                  }}
                >
                            <span
                              style={{
                                color: '#333',
                                fontSize: '22px',
                                fontFamily: 'Linotte-Regular'
                              }}
                            >
                              {'Mã QR: '}
                            </span>
                  <br/>
                  <div className='text-center'>
                    <img style={{ width: '100%', borderRadius: '5px', marginTop: '10px' }} src={`https://img.vietqr.io/image/mbbank-1988288288-compact2.jpg?amount=50000&addInfo=UNG%20HO%20ANBI%20${currentUser.user.phone}&accountName=TRAN%20NGOC%20THIEN`} alt=""/>
                  </div>
                </div>
              </div>
            </div>
            }


            <div
              style={{
                width: '100%',
                //height: '60px',
                //paddingTop: '30px',
                paddingLeft: '20px',
                backgroundColor: '#333',
                paddingRight: '20px',
                justifyContent: 'space-between',
                display: 'flex'
              }}
            >
              <h1 style={{
                color: 'white',
                fontFamily: 'Linotte-Heavy',
                fontSize: '24px'
              }}>
                {/**/}
              </h1>
              <div>
                <h1
                  onClick={() => dispatch({
                    type: 'SET_LANGUAGE',
                    payload: {
                      language: 'VI'
                    }
                  })}
                  style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                  VIE
                </h1>
                <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                  |
                </h1>
                <h1
                  onClick={() => dispatch({
                    type: 'SET_LANGUAGE',
                    payload: {
                      language: 'EN'
                    }
                  })}
                  style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                  ENG
                </h1>
              </div>
            </div>

            <div className='position-relative'>
              <p
                className='text-center m-0 position-absolute w-100'
                style={{
                  fontFamily: 'Linotte-Bold',
                  fontSize: '32px',
                  color: '#fff',
                  textTransform: 'uppercase',
                  top: '200px',
                  left: '50%',
                  transform: 'translate(-50%, 0)',
                }}
              >
                {currentUser.user.name}
              </p>

              {/*ảnh full*/}
              <img style={{ width: WIDTH, height: 'auto' }}
                   src={LANGUAGE === 'VI' ? `https://backend.robotanan.com/xmas-image/v${todayResult}.jpg` : `https://backend.robotanan.com/xmas-image/e${todayResult}.jpg`} alt=""
              />
            </div>

            <div
              className='d-none'
              style={{
                display: LANGUAGE === 'VI' ? 'block' : 'none',
                padding: '20px',
                textAlign: "center"
              }}
            >
              <audio ref={audioElement1} controls>
                <source src={`https://backend.robotanan.com/daily-audio-vn/${currentUser.user.scan_daily_result}.mp3`} type="audio/wav" />
              </audio>
            </div>

            <div
              className='d-none'
              style={{
                display: LANGUAGE === 'VI' ? 'none' : 'block',
                padding: '20px',
                textAlign: "center"
              }}
            >
              <audio ref={audioElement2} controls>
                <source src={`https://backend.robotanan.com/daily-audio-en/a${currentUser.user.scan_daily_result}.mp3`} type="audio/wav" />
              </audio>
            </div>


          </>
        }

        <Link to={{
          pathname: '/customer/update-family-phone',
          state: { test: "hello" }
        }}
          className='d-none'
        >
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff', textTransform: 'uppercase' }}>
                {LANGUAGE === 'VI'
                  ? 'Cập nhật mã gia đình và sức khỏe tinh thần'
                  : 'Family and mental health code update'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-password',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff', textTransform: 'uppercase' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi mật khẩu'
                  : 'Change Password'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-name',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff', textTransform: 'uppercase' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi tên'
                  : 'Change Name'
                }
              </h4>
            </div>
          </div>
        </Link>

        <div style={{ padding: '10px' }}>
          <div
            onClick={() => dispatch(auth.actions.logout())}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
            <h4 style={{ color: '#fff', textTransform: 'uppercase' }}>
              {LANGUAGE === 'VI'
                ? 'Đăng xuất'
                : 'Log out'
              }
            </h4>
          </div>
        </div>

        {/*menu*/}
        <MenuTwo active='noel-daily'/>
      </div>

    </div>
  )
}

export {FullNoelDaily}
