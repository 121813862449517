/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {Login} from "./Login";
import {Registration} from "./Registration";
import {ForgotPassword} from "./ForgotPassword";
import axios from "axios";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    // .min(10, 'Số điện thoại phải là 10 ký tự')
    // .max(10, 'Số điện thoại phải là 10 ký tự')
    .required('Số điện thoại là bắt buộc'),
  password: Yup.string()
    // .min(6, 'Mật khẩu từ 6 đến 16 ký tự')
    // .max(16, 'Mật khẩu từ 6 đến 16 ký tự')
    .required('Họ và tên là bắt buộc'),
  agency: Yup.string()
    .required('Mã đại lý là bắt buộc'),
})


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Register() {
  const [loading, setLoading] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const dispatch = useDispatch()
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const result = searchParams.get("result");
  console.log({result})

  const initialValues = {
    email: '',
    password: '',
    agency: '',
    result: result,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      axios.post('https://backend.robotanan.com/api/customer/noel-register', {
        phone: values.email,
        name: values.password,
        agency: values.agency,
        result: values.result,
      }).then(data => data)
        .then((resData) => {
          setLoading(false)
          console.log({resData})
          if (resData.data.error_code === 1) {
            setStatus('Đăng ký thành công, tới trang đăng nhập sau 3 giây')
            setTimeout(() => {
              window.location.replace('/auth/login?phone=' + values.email)
            }, 3000)
          } else {
            setStatus(resData.data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
            setSubmitting(false)
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    },
  })

  return (

    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: window.innerWidth < 400 ? `url(${toAbsoluteUrl('/media/logos/login-screen.jpg')})` : `url(${toAbsoluteUrl('/media/logos/login-screen-pc.jpg')})`,
        backgroundSize: '100% 100%'
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-anan-dark.png')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-3'>
              <h1 style={{ fontSize: '24px' }} className='mb-3'>Đăng ký Anbi Christmas</h1>
            </div>
            <div className='text-center mb-10'>
              <h1 style={{ color: '#acadb2', fontSize: '20px' }} className='mb-3'>Sign Up to Anbi Christmas</h1>
            </div>
            {/* begin::Heading */}

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <></>
            )}

            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-2 mb-0'>Họ và tên / &nbsp;</label>
                  <label style={{ color: '#acadb2' }} className='form-label fw-bolder fs-3 mb-0'>Full name</label>
                  {/* end::Label */}
                </div>
                <label
                  className='fs-4 d-none'
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  style={{ cursor: 'pointer', float: 'right', color: '#0d6de3', order: 2, fontWeight: 'bold' }}
                >
                  {visiblePassword
                    ?
                    'Ẩn / Hide'
                    :
                    'Hiện / Show'
                  }
                </label>
              </div>
              <input
                type={'text'}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-2',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-2 fw-bolder text-dark'>Số điện thoại / &nbsp;</label>
              <label style={{ color: '#acadb2' }} className='form-label fs-3 fw-bolder'>Phone number</label>
              <input
                placeholder=''
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-2',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-2 fw-bolder text-dark'>Mã đại lý / &nbsp;</label>
              <label style={{ color: '#acadb2' }} className='form-label fs-3 fw-bolder'>Agency</label>
              <input
                placeholder=''
                {...formik.getFieldProps('agency')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-2',
                  {'is-invalid': formik.touched.agency && formik.errors.agency},
                  {
                    'is-valid': formik.touched.agency && !formik.errors.agency,
                  }
                )}
                type='text'
                name='agency'
                autoComplete='off'
              />
              {formik.touched.agency && formik.errors.agency && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.agency}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label fs-2'>Đăng ký / Sign up</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
              Vui lòng chờ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                )}
              </button>

            </div>
            {/* end::Action */}
          </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://www.robotanan.com/' className='text-muted text-hover-primary px-2 fs-4' target='_blank'>
            Liên hệ với chúng tôi
          </a>
        </div>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='/auth/forgot-password' className='text-muted text-hover-primary px-2 fs-4' target='_self'>
            Quên mật khẩu
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>

  )
}
