import React, {useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import * as auth from "../modules/auth/redux/AuthRedux";

const CustomerListStudy = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [errorMessage, setErrorMessage] = useState('');
  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;
  const dispatch = useDispatch()
  const isFirst = currentUser.user.is_first;
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <>
      {isActive && isScan
        ?
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fedac0'
                }}
              >
                <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                     src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/list-study-0.jpg' : '/media/logos/list-study-0e.jpg')}
                     alt=""
                />
                <a href="https://www.youtube.com/watch?v=XNlQTVDHNlg"  target='_blank'>
                  <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                       src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/huong-dan.jpg' : '/media/logos/huong-dane.jpg')}
                       alt=""
                  />
                </a>
                <Link to='/customer/study'>
                  <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                       src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/list-study-1.jpg' : '/media/logos/list-study-1e.jpg')}
                       alt=""
                  />
                </Link>
                <Link to='/customer/study-think'>
                  <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                       src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/list-study-2.jpg' : '/media/logos/list-study-2e.jpg')}
                       alt=""
                  />
                </Link>
                <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                     src={toAbsoluteUrl('/media/logos/list-study-3.jpg')}
                     alt=""
                />

                <MenuTwo active='study'/>
              </div>

            </div>
          </>
        :
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff',
                  padding: '20px'
                }}
              >
                {LANGUAGE === 'VI' && <>
                  <h2>Mời bạn liên hệ với cộng tác viên của Anbi qua số điện thoại <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> để được hỗ trợ mở khóa bản phân tích.</h2>
                </>}
                {LANGUAGE === 'EN' && <>
                  <h2>Please contact Anbi's collaborators via phone number <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> for support unlocking the analysis.</h2>
                </>}

                <div style={{ padding: '10px', display: !isFirst ? 'block' : 'none' }}>
                  <div
                    onClick={() => dispatch(auth.actions.logout())}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                    <h4 style={{ color: '#fff' }}>
                      {LANGUAGE === 'VI'
                        ? 'Đăng xuất'
                        : 'Log out'
                      }
                    </h4>
                  </div>
                </div>

              </div>

              <MenuTwo active='study' />

            </div>
          </>
      }


    </>
  )

}

export {CustomerListStudy}
