export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_IS_FIRST_LOGIN = "SET_IS_FIRST_LOGIN"

const initialState = {
  language: 'VI',
  isFirstLogin: true,
}

export default function userReducer(state = initialState, payload) {
  switch (payload.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload.payload.language,
      }
    case SET_IS_FIRST_LOGIN:
      return {
        ...state,
        isFirstLogin: false,
      }
    default:
      return state
  }
}
